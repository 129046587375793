.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  background-color: var(--color-sapphire-5);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 30rem;
  margin: 2.5rem;
}

.top-text {
  font-size: 1.5rem;
  color: var(--color-sapphire-100);
  line-height: 1.5rem;
  text-transform: capitalize;
}

.bottom-text {
  color: var(--color-charcoal-100);
  line-height: 1.5rem;
  text-align: center;
}
