.alert {
  border-radius: 0.25rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}

/* Size */
.alert-parent {
  padding: 1rem;
}

/* Text Weight */
.alert-text-light {
  line-height: 1.5rem;
  letter-spacing: 0.01rem;
  font-size: 1rem;
  font-weight: var(--font-weight-base);
}

/* Border Edge */
.alert-left {
  border-left: 0.25rem solid;
}

/* Colors */
.alert-yellow {
  background-color: var(--color-gold-10);
  border-color: var(--color-gold-100);
}

.alert-red {
  background-color: var(--color-ruby-10);
  border-color: var(--color-ruby-100);
}

/* Text Colors */
.alert-text-black {
  color: var(--color-neutral-black-100);
}

.transitionOn {
  -webkit-animation: transitionFrame 0.5s ease;
  animation: transitionFrame 0.5s ease;
}

.boxShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

@keyframes transitionFrame {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 768px */
@media screen and (max-width: 48em), screen and (max-height: 20em) {
  .alert {
    line-height: 1.5rem;
  }

  .alert-parent {
    padding: 0.5em;
  }

  .alert-text-light {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
