.loader {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
