.loader {
  display: flex;
  z-index: 998;
}

.help-link:visited {
  color: var(--color-sapphire-150);
}

.help-link:hover {
  color: var(--color-sapphire-90);
}

.help-link:active {
  color: var(--color-sapphire-130);
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-sapphire-5);
  width: 100%;
  height: 100%;
  z-index: 998;
}

.page-content > svg {
  max-width: 17.5rem;
}

.page-content {
  display: flex;
  font-family: Lato-Regular, sans-serif;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  text-align: center;
  gap: 2rem;
}

.content-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 1.5rem;
}

.title {
  font-weight: var(--font-weight-bold);
  color: var(--color-navy-100);
  font-size: 18pt;
  line-height: 2rem;
  letter-spacing: 0.01em;
}

.button {
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-align: center;
  border-radius: 4px;
  background-color: var(--color-sapphire-100);
  color: white;
  box-shadow: none;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:disabled {
  cursor: not-allowed;
  background-color: var(--color-sapphire-100-opacity-40);
  border: 1px solid var(--color-sapphire-100);
}

.button:enabled:hover {
  background-color: var(--color-sapphire-90);
  box-shadow: 0 0 0 4px var(--color-sapphire-30);
}

.button:enabled:active {
  text-decoration: none;
  background-color: var(--color-sapphire-130);
  box-shadow: none;
}

.hidden {
  display: none;
}
